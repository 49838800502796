import { FC, useState, MouseEvent } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { Visibility, VisibilityOff, Info } from '@mui/icons-material';
import { useAuthControllerLoginMutation } from 'src/app/services/api.generated';
import { toast } from 'react-toastify';
import { formikOnSubmitType } from 'src/types/formTypes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import SendMail from '../changePassword/SendMail';
import { LoadingButton } from '@mui/lab';

export const SignIn: FC = () => {
  const { t } = useTranslation(),
    navigate = useNavigate();

  const [loginUser, { isLoading }] = useAuthControllerLoginMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const frequentValidation = yup
    .string()
    .min(3, t('tooShort') || '')
    .max(50, t('tooLong') || '')
    .required(t('required') || '');

  const formInitialValues = {
    username: '',
    password: '',
    twoFactorToken: '',
  };
  const formValidation = yup.object().shape({
    username: frequentValidation,
    password: frequentValidation,
    twoFactorToken: yup.string(),
  });

  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { username, password, twoFactorToken },
    { setSubmitting }
  ) => {
    loginUser({
      loginDto: { username, password, twoFactorToken },
    })
      .unwrap()
      .then((res) => {
        if (!res) return;
        navigate('/');
      })
      .catch((err) => {
        const { statusCode, message } = err.data;
        if (statusCode === 401) {
          message?.includes('2FA')
            ? toast.error(t('login.2fa_required'))
            : toast.error(t('login.401'));
        }
      });
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidation}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={submitHandler}
      >
        {({ errors, touched, getFieldProps }) => (
          <Form autoComplete="on">
            <Stack
              rowGap={{ xs: 2.5, md: 3, lg: 4 }}
              sx={{ maxWidth: '400px', mx: 'auto' }}
            >
              <Typography fontSize={25} fontWeight={700}>
                {t('login.title')}
              </Typography>
              <TextField
                size="small"
                fullWidth
                inputProps={{
                  enterKeyHint: 'next',
                  autoComplete: 'on',
                  name: 'username',
                }}
                label={t('username') + ' *'}
                autoFocus
                error={Boolean(errors.username && touched.username)}
                helperText={errors.username}
                {...getFieldProps('username')}
              />
              <TextField
                size="small"
                fullWidth
                label={t('password') + ' *'}
                type={showPassword ? 'text' : 'password'}
                inputProps={{
                  enterKeyHint: 'done',
                  autoComplete: 'on',
                  name: 'password',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={showPasswordHandler}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility sx={{ fill: 'white' }} />
                        ) : (
                          <VisibilityOff sx={{ fill: 'white' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.password && touched.password)}
                helperText={errors.password}
                {...getFieldProps('password')}
              />
              <TextField
                size="small"
                fullWidth
                label={t('twoFactorToken')}
                inputProps={{
                  enterKeyHint: 'done',
                  autoComplete: 'off',
                  name: 'twoFactorToken',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={t('twoFactorTooltip')} placement="top">
                        <IconButton
                          aria-label=""
                          edge="end"
                        >
                          <Info sx={{ fill: 'white' }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.twoFactorToken && touched.twoFactorToken)}
                helperText={errors.twoFactorToken}
                {...getFieldProps('twoFactorToken')}
              />
              <Button
                variant="text"
                sx={{
                  color: ({ palette }) => palette.primary.main,
                  fontWeight: 700,
                  textAlign: 'right',
                  display: 'block',
                  ml: 'auto',
                }}
                onClick={() => setShowDialog(true)}
              >
                {t('forgotYourPassword')}
              </Button>
              <Stack justifyContent="center" alignItems="center">
                <LoadingButton
                  loading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {t('signIn')}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
      <SendMail
        maxWidth="xs"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};
